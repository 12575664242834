import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { FinanceiroBoleto } from 'src/app/agrow/modelo/financeiroBoleto';
import bibIcone from 'src/app/biblioteca/bibIcone';
import { bibServicoBoletoTecnoSpeed } from 'src/app/biblioteca/bibServicoBoletoTecnoSpeed';
import { AlteracaoRemessa } from 'src/app/integracao/tecnospeed/boleto/AlteracaoRemessa';
import { Boleto } from 'src/app/integracao/tecnospeed/boleto/Boleto';
import { BoletoAlteracao } from 'src/app/integracao/tecnospeed/boleto/BoletoAlteracao';
import { BoletoEmail } from 'src/app/integracao/tecnospeed/boleto/BoletoEmail';
import { Impressao } from 'src/app/integracao/tecnospeed/boleto/Impressao';
import { Conta } from 'src/app/modelo/conta';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { Financeiro } from 'src/app/modelo/financeiro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Loja } from 'src/app/modelo/loja';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ParceiroDestino } from 'src/app/modelo/parceiroDestino';
import { ParceiroEndereco } from 'src/app/modelo/parceiroEndereco';
import { ParceiroTelefone } from 'src/app/modelo/parceiroTelefone';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { FinanceiroFrmComponent } from 'src/app/pagina/financeiro/frm/financeiroFrm.component';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Util } from 'src/app/utilitario/util';
import filtros from './filtro';

@Component({
  templateUrl: './financeiroBoleto.component.html',
  styleUrls: ['./financeiroBoleto.component.css'],
})
export class FinanceiroBoletoComponent extends PaginaComponent {
  public bibIcone = bibIcone;
  public bibServicoBoletoTecnoSpeed: bibServicoBoletoTecnoSpeed;
  public componente: any;
  public contagemBoletoAlterar: number = 0;
  public contas: Conta[] = [];
  public filtros: Filtro[] = filtros;
  public financeiroBoletos: FinanceiroBoleto[] = [];
  public financeiros: Financeiro[] = [];
  public lojaCnpj: string;
  public parceiroDestinos: ParceiroDestino[] = [];
  public parceiroEnderecos: ParceiroEndereco[] = [];
  public parceiroTelefones: ParceiroTelefone[] = [];
  public parceiros: Parceiro[] = [];
  public selecionado: string = 'N';
  public util: Util = new Util();
  public valorTotalBoleto: number = 0;
  public contaNome: string;
  public intervaloAtualizarBoletos: any;
  public dataAtualizacao: Date;
  public tempoDeListagem: number = 10000;
  public listouPorParceiro: boolean = false;
  public primeiraConsulta: boolean = true;

  ngOnInit(): void {
    this.ehListarAtalhoMovimentacao();
  }

  ehListarAtalhoMovimentacao(): void {
    const idMovimentacao: number = this.utilSessao.getIdentificacao('idMovimentacao')?.conteudo;
    if (idMovimentacao) {
      this.listar([], 0, 0, idMovimentacao);
    }
  }

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0, idMovimentacao: number = null, primeiraConsulta = true): void {
    this.primeiraConsulta = primeiraConsulta;
    criterios.push(new Criterio('ATIVO', 'S'));
    criterios.push(new Criterio('GERAR_OU_GEROU_BOLETO', 'S'));
    criterios.push(new Criterio('TIPO_TITULO_BOLETO', 'S'));
    criterios.push(new Criterio('ID_CONTA_PREENCHIDO_BOLETO', 'S'));
    if (idMovimentacao) {
      criterios.push(new Criterio('ID_MOVIMENTACAO', idMovimentacao));
    }
    let contagemCriterioParceiro: number = 0;
    criterios.forEach((criterio) => {
      if (criterio.nome == 'ID_PARCEIRO' && criterio.valor != null) {
        contagemCriterioParceiro++;
      }
    });
    if (contagemCriterioParceiro > 0) {
      this.listouPorParceiro = true;
    } else {
      this.listouPorParceiro = false;
    }
    if (this.listouPorParceiro == false) {
      criterios.push(new Criterio('TIPO', 'R'));
    }
    this.valorTotalBoleto = 0;
    clearInterval(this.intervaloAtualizarBoletos);
    this.utilSessao.iniciarProcesso();
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.financeiro, false, true, true, this.primeiraConsulta).subscribe((res) => {
      this.financeiros = this.plainToClass(Financeiro, res) as any;
      if (this.financeiros.length > 0) {
        this.unificarFinanceirosDeUmMesmoBoleto();
        this.lojaCnpj = this.util.retirarCaracteresInvalidos(this.utilSessao.getLojas().find((loja) => loja.id == this.financeiros[0].idLoja).cnpj);
        this.listarFinanceiroBoletos(
          this.financeiros.map((financeiro) => financeiro.id),
          idMovimentacao
        );
        this.financeiros.forEach((financeiro) => {
          let jaEstaNaLista: boolean = this.financeiroBoletos.find((financeiroBoletoLista) => financeiroBoletoLista.idFinanceiro == financeiro.id) != undefined;
          financeiro.selecionado = jaEstaNaLista ? 'S' : 'N';
          this.valorTotalBoleto += jaEstaNaLista ? financeiro.valor : 0;
          financeiro.difereBoleto = (financeiro.valorRestante > 0 && financeiro.financeiroBoleto && financeiro.financeiroBoleto.status == 100 && financeiro.valorRestante != financeiro.financeiroBoleto.valor) ? true : false;
        });
      }
      this.listarContagemBoletoAtualizar();
      this.utilSessao.finalizarProcesso();
    });
    let abaAtiva: boolean = true;
    document.addEventListener('visibilitychange', () => {
      abaAtiva = abaAtiva == true ? false : true;
    });
    this.intervaloAtualizarBoletos = setInterval(() => {
      if (this.financeiros.filter((financeiro) => financeiro.financeiroBoleto?.situacao == 'PROCESSANDO' || financeiro.financeiroBoleto?.situacao == 'Em processo de registro').length > 0 && abaAtiva) {
        this.listar(criterios, posicao, quantidade, idMovimentacao, this.primeiraConsulta);
        this.dataAtualizacao = new Date();
        this.tempoDeListagem = 30000;
      }
    }, this.tempoDeListagem);
  }

  unificarFinanceirosDeUmMesmoBoleto(): void {
    let financeirosParaAgrupar: Financeiro[] = this.financeiros.filter((financeiro) => financeiro.idFinanceiroBoleto != null);
    let boletosAgrupados: { [id: string]: Financeiro[] } = {};
    financeirosParaAgrupar.forEach((boletoFinanceiro) => {
      if (!boletosAgrupados[boletoFinanceiro.idFinanceiroBoleto]) {
        boletosAgrupados[boletoFinanceiro.idFinanceiroBoleto] = [];
      }
      boletosAgrupados[boletoFinanceiro.idFinanceiroBoleto].push(boletoFinanceiro);
    });
    this.financeiros = this.financeiros.filter((financeiro) => financeiro.idFinanceiroBoleto == null);
    for (let boletoAgrupado in boletosAgrupados) {
      let _boletoAgrupado = financeirosParaAgrupar.find((financeiro) => financeiro.idFinanceiroBoleto.toString() == boletoAgrupado);
      _boletoAgrupado.valorRestante = financeirosParaAgrupar.filter(financeiro => financeiro.idFinanceiroBoleto.toString() == boletoAgrupado).reduce((soma, financeiro) => soma + (financeiro.tipo == 'R' ? financeiro.valorRestante : financeiro.valorRestante * -1), 0);
      _boletoAgrupado.dataVencimento = financeirosParaAgrupar.filter(financeiro => financeiro.idFinanceiroBoleto.toString() == boletoAgrupado).sort((a, b) => a.dataVencimento.getMilliseconds() - b.dataVencimento.getMilliseconds())[0].dataVencimento;
      this.financeiros.push(_boletoAgrupado)
    }
  }

  listarContagemBoletoAtualizar(): void {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('FINANCEIRO_DIFERE_BOLETO', 1));
    criterios.push(new Criterio('STATUS', 100));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.financeiroBoleto).subscribe((res) => {
      const financeiroBoletoDiferentes: FinanceiroBoleto[] = this.plainToClass(FinanceiroBoleto, res) as any;
      this.contagemBoletoAlterar = financeiroBoletoDiferentes.length;
      this.atualizarDifereBoleto(financeiroBoletoDiferentes);
    });
  }

  atualizarDifereBoleto(financeiroBoletos: FinanceiroBoleto[]): void {
    financeiroBoletos.forEach((financeiroBoleto) => {
      this.financeiros.filter((financeiro) => financeiro.id == financeiroBoleto.idFinanceiro).forEach((financeiro) => {
        financeiro.difereBoleto = true;
      })
    })
  }

  // atualizarDifereBoletoUnificado(financeiroBoletos: FinanceiroBoleto[]): void {
  //   financeiroBoletos.forEach((financeiroBoleto) => {
  //     this.financeiros.filter((financeiro) => financeiro.idFinanceiroBoleto == financeiroBoleto.id).forEach((financeiro) => {
  //       financeiro.difereBoleto = true;
  //     })
  //   })
  // }

  private listarFinanceiroBoletos(idFinanceiros: number[], idMovimentacao: number = null): void {
    let criterios: Criterio = new Criterio('IDS_FINANCEIRO', idFinanceiros.toString());
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.financeiroBoleto, false, true, true, this.primeiraConsulta).subscribe((res) => {
      const idFinanceiros: number[] = this.financeiros.map((financeiro) => financeiro.id);
      const idContas: number[] = this.financeiros.filter((financeiro) => financeiro.idConta != null).map((financeiro) => financeiro.idConta);
      const idParceiros: number[] = this.financeiros.filter((financeiro) => idFinanceiros.includes(financeiro.id)).map((financeiro) => financeiro.idParceiro);
      this.listarContasParceiros(idContas, idParceiros);
      this.vincularFinanceiroBoleto(this.plainToClass(FinanceiroBoleto, res) as any, idMovimentacao);
    });
  }

  private vincularFinanceiroBoleto(financeiroBoletos: FinanceiroBoleto[], idMovimentacao: number = null): void {
    this.financeiros.forEach((financeiro) => {
      const financeiroBoleto: FinanceiroBoleto = financeiroBoletos.find((financeiroBoleto) => financeiroBoleto.idFinanceiro == financeiro.id);
      if (financeiroBoleto) {
        financeiro.financeiroBoleto = financeiroBoleto;
      }
      if (idMovimentacao) {
        financeiro.selecionado = 'N';
        this.setFinanceiroSelecionado(financeiro);
      }
    });
  }

  setFinanceiroSelecionado(financeiro: Financeiro): void {
    if (!financeiro.financeiroBoleto || financeiro.financeiroBoleto?.situacao == 'REJEITADO' || financeiro.financeiroBoleto?.situacao == 'AGUARDANDO' || financeiro.financeiroBoleto?.situacao.includes('FALHA') || financeiro.financeiroBoleto?.situacao.toUpperCase().indexOf('BOLETO: BOLETO COM NOSSO NÚMERO') != -1 || financeiro.financeiroBoleto?.situacao.toUpperCase().indexOf('ERRO NO PROCESSAMENTO DE BOLETO') != -1) {
      financeiro.selecionado = financeiro.selecionado == 'S' ? 'N' : 'S';
      let financeiroBoleto: FinanceiroBoleto = financeiro.financeiroBoleto != null ? financeiro.financeiroBoleto : this.criarFinanceiroBoleto(financeiro, null);
      if (financeiro.selecionado == 'S') {
        let jaEstaNaLista: boolean = this.financeiroBoletos.find((financeiroBoletoLista) => financeiroBoletoLista.idFinanceiro == financeiroBoleto.idFinanceiro) != undefined;
        if (jaEstaNaLista == false) {
          this.financeiroBoletos.push(financeiroBoleto);
          this.valorTotalBoleto += financeiro.valorRestante * (financeiro.tipo == 'D' ? -1 : 1);
        }
      } else {
        let index: number = this.financeiroBoletos.findIndex((financeiroBoleto) => financeiroBoleto.idFinanceiro == financeiro.id);
        this.financeiroBoletos.splice(index, 1);
        this.valorTotalBoleto -= financeiro.valorRestante * (financeiro.tipo == 'D' ? -1 : 1);
      }
    } else {
      financeiro.selecionado = 'N';
    }
  }

  criarFinanceiroBoleto(financeiro: Financeiro, valorRestanteTotal: number, idsFinanceiros: number[] = []): FinanceiroBoleto {
    let financeiroBoleto: FinanceiroBoleto = new FinanceiroBoleto();
    financeiroBoleto.dataVencimento = financeiro.dataVencimento;
    financeiroBoleto.idEmpresa = financeiro.idEmpresa;
    financeiroBoleto.valor = valorRestanteTotal != null ? valorRestanteTotal : financeiro.valorRestante;
    financeiroBoleto.idConta = financeiro.idConta;
    financeiroBoleto.idFinanceiro = financeiro.id;
    financeiroBoleto.idLoja = financeiro.idLoja;
    financeiroBoleto.dataGeracao = new Date();
    financeiroBoleto.situacao = 'AGUARDANDO';
    financeiroBoleto.status = 0;
    financeiroBoleto.idsFinanceiros = idsFinanceiros;
    return financeiroBoleto;
  }

  abrirModalFinanceiro(id: number) {
    event.preventDefault();
    this.utilSessao.setIdentificacao(new Identificacao('modalBloqueada', true));
    this.utilSessao.setIdentificacao(new Identificacao('parametro', 'R'));
    this.utilSessao.setIdentificacao(new Identificacao('objeto', id));
    this.componente = FinanceiroFrmComponent;
  }

  listarContasParceiros(idContas: number[], idParceiros: number[]) {
    this.comunicacaoService.listar(new Transporte(new Criterio('IDS', idContas.toString())), this.bibServico.conta, false, true, true, this.primeiraConsulta).subscribe((res) => {
      this.contas = this.plainToClass(Conta, res) as any;
      let homologacao: boolean = false;
      this.contas.forEach((conta) => {
        this.contaNome = conta.nome;
        if (conta.homologacao == 'S') {
          homologacao = true;
        }
      });
      this.bibServicoBoletoTecnoSpeed = new bibServicoBoletoTecnoSpeed(homologacao);
      this.listarParceiros(idParceiros);
    });
  }

  listarParceiros(idParceiros: number[]): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PARCEIROS', idParceiros.toString())), this.bibServico.parceiro, false, true, true, this.primeiraConsulta).subscribe((res) => {
      this.parceiros = this.plainToClass(Parceiro, res) as any;
      this.listarParceiroEndereco(this.parceiros.map((parceiro) => parceiro.id));
    });
  }

  private listarParceiroEndereco(idParceiros: number[]): void {
    let criterios: Criterio[] = [new Criterio('ID_PARCEIROS', idParceiros.toString()), new Criterio('ID_VINCULO', 3)];
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.parceiroEndereco, false, true, true, this.primeiraConsulta).subscribe((res) => {
      this.parceiroEnderecos = this.plainToClass(ParceiroEndereco, res) as any;
      this.listarParceiroDestinos(idParceiros);
    });
  }

  private listarParceiroDestinos(idParceiros: number[]): void {
    let criterios: Criterio[] = [new Criterio('ID_PARCEIROS', idParceiros.toString())];
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.parceiroDestino, false, true, true, this.primeiraConsulta).subscribe((res) => {
      this.parceiroDestinos = this.plainToClass(ParceiroDestino, res) as any;
      this.listarParceiroTelefone(idParceiros);
    });
  }

  private listarParceiroTelefone(idParceiros: number[]): void {
    let criterios: Criterio[] = [new Criterio('ID_PARCEIROS', idParceiros.toString()), new Criterio('ID_VINCULO', 3)];
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.parceiroTelefone, false, true, true, this.primeiraConsulta).subscribe((res) => {
      this.parceiroTelefones = this.plainToClass(ParceiroTelefone, res) as any;
      this.primeiraConsulta = false;
    });
  }

  ehValidoSelecionado(): boolean {
    if (this.financeiros.filter((financeiro) => financeiro.selecionado == 'S').length > 0) {
      return true;
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.nenhumBoletoFoiSelecionado));
      return false;
    }
  }

  validarValorTotalBoleto(): boolean {
    let valorTotal: number = 0;
    for (let financeiro of this.financeiros) {
      if (financeiro.selecionado == 'S') {
        valorTotal += financeiro.valorRestante;
      }
    }
    if (this.util.arredondar(this.valorTotalBoleto) != this.util.arredondar(valorTotal)) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.valorTotalBoletoIncorreto));
      return false;
    }
    return true;
  }

  validarInformacoesSacado(): boolean {
    const financeirosValidar: Financeiro[] = this.financeiros;
    financeirosValidar.forEach((financeiro) => (financeiro.selecionado != 'N'));
    this.listarFinanceiroBoletos(financeirosValidar.map((financeiro) => financeiro.id), null);
    for (let financeiroValidar of financeirosValidar) {
      const financeiro: Financeiro = this.financeiros.find((financeiroBusca) => financeiroBusca.id == financeiroValidar.id);
      const parceiro: Parceiro = this.getParceiro(financeiro.idParceiro, financeiro.idParceiroDestino ? financeiro.idParceiroDestino : null);
      if (parceiro && !parceiro.cnpjCpf) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.parceiroNaoPossuiCpfCnpjCadastrado));
        return false;
      } else {
        if (!this.util.validarCnpjCpf(parceiro.cnpjCpf)) {
          this.utilSessao.setResultado(new Resultado(false, parceiro.tipoPessoa == 'F' ? this.bibDialogo.cpfIncorreto : this.bibDialogo.cpfIncorreto));
          return false;
        }
      }
    }
    return true;
  }

  validarDataVencimento(): boolean {
    const financeirosValidar: Financeiro[] = this.financeiros.filter(financeiro => financeiro.selecionado != 'N');
    const dataAtual = new Date();
    dataAtual.setDate(dataAtual.getDate() - 1);
    for (let financeiroValidar of financeirosValidar) {
      const dataVencimento = new Date(financeiroValidar.dataVencimento);
      if (dataVencimento < dataAtual) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.dataVencimentoMenorDataAtual));
        return false;
      }
    }
    return true;
  }

  validarEmissao(): boolean {
    const ehValidoSelecionado = this.ehValidoSelecionado();
    const validarValorTotalBoleto = this.validarValorTotalBoleto();
    const validarInformacoesSacado = this.validarInformacoesSacado();
    const validarDataVencimento = this.validarDataVencimento();
    return ehValidoSelecionado && validarValorTotalBoleto && validarInformacoesSacado && validarDataVencimento;
  }

  persistirFinanceiroBoleto(agrupar: boolean = false): void {
    if (this.validarEmissao()) {
      if (agrupar) {
        this.financeiroBoletos = [];
        const idsFinanceiros: number[] = this.financeiros.filter((financeiro) => financeiro.selecionado == 'S' && financeiro.financeiroBoleto == null).map((financeiro) => financeiro.id);
        let financeirosOrdenados = this.financeiros.filter((financeiro) => financeiro.selecionado == 'S' && financeiro.financeiroBoleto == null).sort((a, b) => a.dataVencimento.getMilliseconds() - b.dataVencimento.getMilliseconds());
        this.financeiroBoletos.push(this.criarFinanceiroBoleto(financeirosOrdenados[0], this.valorTotalBoleto, idsFinanceiros));
      }
      super.persistir(new Transporte(this.financeiroBoletos), this.bibServico.financeiroBoleto, null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.financeiros.forEach((financeiro) => (financeiro.selecionado = 'N'));
          this.atualizaFinanceiroBoleto(this.financeiroBoletos.filter((financeiroBoleto) => financeiroBoleto.idFinanceiro != null).map((financeiroBoleto) => financeiroBoleto.idFinanceiro)).subscribe((res) => {
            this.financeiroBoletos = this.plainToClass(FinanceiroBoleto, res) as any;
            this.comunicacaoService.listar(new Transporte([new Criterio('ID', this.financeiroBoletos[0].idConta)]), this.bibServico.conta).subscribe((res) => {
              let conta: Conta = this.plainToClass(Conta, res[0]) as any;
              let boletos: Boleto[] = this.criarBoletos(conta);
              if (boletos.length > 0) {
                this.persistirBoleto(boletos, this.lojaCnpj);
              }
            });
          });
        }
      });
    }
  }

  atualizaFinanceiroBoleto(idFinanceiros: number[]): Observable<FinanceiroBoleto> {
    return this.comunicacaoService.listar(new Transporte(new Criterio('IDS_FINANCEIRO', idFinanceiros.toString())), this.bibServico.financeiroBoleto);
  }

  criarBoletos(conta: Conta): Boleto[] {
    if (this.financeiroBoletos.length > 0) {
      if (conta.idLojaBoleto) {
        this.lojaCnpj = this.util.retirarCaracteresInvalidos(this.utilSessao.getLojas().find((loja) => loja.id == conta.idLojaBoleto).cnpj);
      }
    }
    let boletos: Boleto[] = [];
    for (let financeiroBoleto of this.financeiroBoletos) {
      const BANCO_DO_BRASIL: boolean = conta.numeroBanco == '001';
      const BRADESCO: boolean = conta.numeroBanco == '237';
      const SICREDI: boolean = conta.numeroBanco == '748';
      const SANTANDER: boolean = conta.numeroBanco == '033';
      const financeiro: Financeiro = this.financeiros.find((financeiroBusca) => financeiroBusca.id == financeiroBoleto.idFinanceiro);
      const parceiro: Parceiro = this.getParceiro(financeiro.idParceiro, financeiro.idParceiroDestino ? financeiro.idParceiroDestino : null);
      const parceiroTelefone: ParceiroTelefone = parceiro.parceiroTelefones.find((parceiroTelefoneBusca) => parceiroTelefoneBusca.idVinculo == 3);
      financeiroBoleto.numero = conta.numeroBoleto;
      let parceiroEndereco: any = null;
      if (financeiro.idParceiroDestino) {
        parceiroEndereco = parceiro.parceiroDestinos.find((parceiroDestino) => (parceiroDestino.id = financeiro.idParceiroDestino));
      } else {
        parceiroEndereco = parceiro.parceiroEnderecos.find((parceiroEnderecoBusca) => parceiroEnderecoBusca.idVinculo == 3);
      }
      if (this.ehValidoParceiroEndereco(parceiro, financeiro.idParceiroDestino ? parceiroEndereco : null) && this.ehValidoParceiroTelefone(parceiroTelefone, conta.numeroBanco)) {
        let boleto: Boleto = new Boleto();
        boleto.CedenteContaNumero = conta.numero;
        boleto.CedenteContaNumeroDV = conta.digito;
        boleto.CedenteConvenioNumero = conta.convenio;
        boleto.CedenteContaCodigoBanco = conta.numeroBanco;
        boleto.SacadoNome = parceiro.razaoSocial;
        if (parceiroTelefone) {
          let ddd: string = parceiroTelefone.telefone.replace('(', '').replace(')', '').substr(0, 2).trim();
          let numero: string = parceiroTelefone.telefone.replace('(', '').replace(')', '').replace('-', '').substr(2, parceiroTelefone.telefone.length).trim();
          boleto.SacadoTelefone = ddd + numero;
          boleto.SacadoCelular = ddd + numero;
        }
        boleto.SacadoCPFCNPJ = parceiro.cnpjCpf ? this.util.retirarCaracteresInvalidos(parceiro.cnpjCpf) : '';
        let emailParceiro: string = parceiro.email;
        if (emailParceiro && emailParceiro.length > 50) {
          if (emailParceiro.split(';').length > 0) {
            emailParceiro.split(';').forEach((email) => {
              if (email.replace(';', '')) {
                boleto.SacadoEmail = email.replace(';', '');
              }
            });
          } else {
            boleto.SacadoEmail = '';
          }
        } else {
          boleto.SacadoEmail = emailParceiro;
        }
        boleto.SacadoEnderecoNumero = parceiroEndereco.numero;
        boleto.SacadoEnderecoBairro = parceiroEndereco.bairro;
        boleto.SacadoEnderecoCEP = parceiroEndereco.cep.replace('-', '');
        boleto.SacadoEnderecoCidade = parceiroEndereco.cidade;
        boleto.SacadoEnderecoComplemento = parceiroEndereco.complemento;
        boleto.SacadoEnderecoLogradouro = parceiroEndereco.tipo + ' ' + parceiroEndereco.endereco;
        boleto.SacadoEnderecoPais = parceiroEndereco.pais;
        boleto.SacadoEnderecoUF = parceiroEndereco.estado;
        boleto.TituloDocEspecie = conta.especie;
        boleto.TituloDataEmissao = this.util.formatarDataBoleto(financeiro.dataNegociacao);
        boleto.TituloDataVencimento = this.util.formatarDataBoleto(financeiro.dataVencimento);
        boleto.TituloNumeroDocumento = financeiro.numeroFiscal ? 'NF ' + financeiro.numeroFiscal.toString() : financeiro.numero.toString();
        boleto.TituloValor = financeiroBoleto.valor != null && financeiroBoleto.valor != 0 ? financeiroBoleto.valor.toFixed(2).replace('.', ',') : financeiro.valorRestante.toFixed(2).replace('.', ',');
        boleto.TituloLocalPagamento = conta.localPagamento;
        boleto.TituloCodDesconto = conta.tipoDesconto;
        boleto.TituloDataDesconto = this.adicionarDiasDatas(financeiro.dataNegociacao, conta.diasDesconto);
        boleto.TituloValorDescontoTaxa = conta.valorDesconto > 0 ? conta.valorDesconto.toFixed(2).replace('.', ',') : '0,00';
        boleto.TituloCodigoJuros = Number(conta.tipoJuros);
        boleto.TituloDataJuros = this.adicionarDiasDatas(financeiro.dataVencimento, conta.diasJuros);
        boleto.TituloValorJuros = conta.taxaJuros > 0 ? conta.taxaJuros.toFixed(2).replace('.', ',') : '0,00';
        boleto.TituloCodigoMulta = conta.tipoMulta;
        boleto.TituloDataMulta = this.adicionarDiasDatas(financeiro.dataVencimento, conta.diasMulta);
        boleto.TituloValorMultaTaxa = conta.percentualMulta > 0 ? conta.percentualMulta.toFixed(2).replace('.', ',') : '0,00';
        boleto.TituloCodProtesto = conta.tipoProtesto;
        boleto.TituloPrazoProtesto = conta.diasProtesto;
        boleto.TituloInstrucoes = conta.instrucoes;
        boleto.TituloCodigoReferencia = financeiro.id.toString();
        if (BANCO_DO_BRASIL || SICREDI || SANTANDER) {
          boleto.TituloNossoNumero = conta.numeroBoleto.toString();
        }
        if (BANCO_DO_BRASIL) {
          boleto.TituloVariacaoCarteira = conta.carteira;
        }
        conta.numeroBoleto++;
        boletos.push(boleto);
      } else {
        return;
      }
    }
    super.persistir(new Transporte([conta]), this.bibServico.conta, null);
    return boletos;
  }

  adicionarDiasDatas(data: Date, dias: number): string {
    if (data && dias) {
      let dataDias: Date = data;
      dataDias = new Date(dataDias.setDate(dataDias.getDate() + dias));
      return this.util.formatarDataBoleto(dataDias);
    }
    return null;
  }

  private getParceiro(idParceiro: number, idParceiroDestino: number): Parceiro {
    let parceiro: Parceiro;
    parceiro = this.parceiros.find((parceiro) => parceiro.id == idParceiro);
    if (idParceiroDestino) {
      parceiro.parceiroDestinos = this.parceiroDestinos.filter((parceiroDestino) => parceiroDestino.idParceiro == parceiro.id && parceiroDestino.id == idParceiroDestino);
    } else {
      parceiro.parceiroEnderecos = this.parceiroEnderecos.filter((parceiroEndereco) => parceiroEndereco.idParceiro == parceiro.id);
    }
    parceiro.parceiroTelefones = this.parceiroTelefones.filter((parceiroTelefone) => parceiroTelefone.idParceiro == parceiro.id);
    return parceiro;
  }

  persistirBoleto(boletos: Boleto[], cnpj: string): void {
    this.comunicacaoTecnoSpeedBoletoService.persistir(this.bibServicoBoletoTecnoSpeed.getUrlBoletoPersistir(this.bibServico.boletoTecnospeed), boletos, cnpj).subscribe(
      (res) => {
        if (res._dados._sucesso.length > 0) {
          this.persistirBoletoTecnospeed(res);
          boletos = []
        } else {
          this.atualizarFinanceiroBoleto(res);
          boletos = []
        }
      },
      (erro) => {
        boletos = []
        if (erro.error._dados == null) {
          this.financeiroBoletos = [];
        }
        erro.error._dados.forEach((falha) => {
          let financeiroBoleto: FinanceiroBoleto = this.financeiroBoletos.find((financeiroBoletoBusca) => financeiroBoletoBusca.numero.toString() == falha.TituloNossoNumero);
          financeiroBoleto.statusDescricao = falha._erro;
          financeiroBoleto.status = 3;
        });
        super.persistir(new Transporte(this.financeiroBoletos), this.bibServico.financeiroBoleto, null).subscribe(() => {
          if (!this.utilSessao.falha) {
            this.vincularFinanceiroBoleto(this.financeiroBoletos);
            this.financeiroBoletos = [];
            this.valorTotalBoleto = 0;
          }
        });
      }
    );
  }

  persistirBoletoTecnospeed(respostaTecnospeed: any): void {
    respostaTecnospeed._dados._sucesso.forEach((sucesso) => {
      let financeiroBoleto: FinanceiroBoleto = this.financeiroBoletos.find((financeiroBoletoBusca) => financeiroBoletoBusca.idFinanceiro.toString() == sucesso.TituloCodigoReferencia);
      financeiroBoleto.idTecnospeed = sucesso.idintegracao;
      if (respostaTecnospeed._status == 'sucesso') {
        financeiroBoleto.protocoloImpressao = sucesso.idImpressao;
      }
      financeiroBoleto.statusDescricao = sucesso.situacao;
      financeiroBoleto.situacao = sucesso.situacao;
      financeiroBoleto.numero = sucesso.TituloNossoNumero;
      financeiroBoleto.status = -1;
    });
    super.persistir(new Transporte(this.financeiroBoletos), this.bibServico.financeiroBoleto, null).subscribe(() => {
      if (!this.utilSessao.falha) {
        this.financeiroBoletos.forEach((financeiroBoleto) => {
          this.comunicacaoTecnoSpeedBoletoService.listar(this.bibServicoBoletoTecnoSpeed.getUrlConsultaBoleto(this.bibServico.boletoTecnospeed, financeiroBoleto.idTecnospeed), this.lojaCnpj).subscribe((res) => {
            this.atualizarSituacaoBoleto(financeiroBoleto, res);
          });
        });
        this.vincularFinanceiroBoleto(this.financeiroBoletos);
        this.valorTotalBoleto = 0;
        this.financeiroBoletos = [];
      }
    });
  }

  atualizarFinanceiroBoleto(respostaTecnospeed: any): void {
    respostaTecnospeed._dados._falha.forEach((erro) => {
      let financeiroBoleto: FinanceiroBoleto = this.financeiroBoletos.find((financeiroBoletoBusca) => financeiroBoletoBusca.idFinanceiro == erro._dados.TituloCodigoReferencia);
      for (var atributo in erro._erro.erros) {
        financeiroBoleto.statusDescricao = atributo + ': ' + Object.values(erro._erro.erros).join('');
      }
      financeiroBoleto.situacao = this.bibDialogo.dialogoBoletoSituacaoErroProcessamento;
      financeiroBoleto.status = 3;
    });
    super.persistir(new Transporte(this.financeiroBoletos), this.bibServico.financeiroBoleto, null).subscribe(() => {
      if (!this.utilSessao.falha) {
        this.vincularFinanceiroBoleto(this.financeiroBoletos);
        this.valorTotalBoleto = 0;
        this.financeiroBoletos = [];
      }
    });
  }

  consultarBoletoTecnospeed(financeiroBoleto: FinanceiroBoleto): void {
    this.comunicacaoTecnoSpeedBoletoService.listar(this.bibServicoBoletoTecnoSpeed.getUrlConsultaBoleto(this.bibServico.boletoTecnospeed, financeiroBoleto.idTecnospeed), this.lojaCnpj).subscribe((res) => {
      this.atualizarSituacaoBoleto(financeiroBoleto, res);
    });
  }

  atualizarSituacaoBoleto(financeiroBoleto: FinanceiroBoleto, respostaTecnospeed: any): void {
    if (respostaTecnospeed._status == 'sucesso') {
      financeiroBoleto.statusDescricao = this.bibDialogo.emProcessoRegistro;
      financeiroBoleto.situacao = this.bibDialogo.emProcessoRegistro;
      financeiroBoleto.status = 20;
      super.persistir(new Transporte(financeiroBoleto), this.bibServico.financeiroBoleto, null).subscribe((res) => {
        if (!this.utilSessao.falha) {
          this.vincularFinanceiroBoleto(this.financeiroBoletos);
          this.valorTotalBoleto = 0;
        }
      });
    }
  }

  desejaGerarPdfBoletoDiferenteFinanceiro(financeiroBoleto: FinanceiroBoleto): void {
    const financeiro: Financeiro = this.financeiros.find((financeiro) => financeiro.id == financeiroBoleto.idFinanceiro);
    if (financeiro.difereBoleto) {
      if (confirm(this.bibDialogo.desejaGerarBoletoDesatualizado)) {
        this.imprimirPdf(financeiroBoleto);
      }
    } else {
      this.imprimirPdf(financeiroBoleto);
    }
  }

  imprimirPdf(financeiroBoleto: FinanceiroBoleto) {
    let impressao: Impressao = this.criarImpressao(financeiroBoleto);
    this.comunicacaoTecnoSpeedBoletoService.persistir(this.bibServicoBoletoTecnoSpeed.getUrlBoletoPdf(this.bibServico.boletoTecnospeed), impressao, this.lojaCnpj).subscribe((res) => {
      this.adicionarProtocoloImpressao(financeiroBoleto, res);
    });
  }

  adicionarProtocoloImpressao(financeiroBoleto: FinanceiroBoleto, respostaTecnospeed: any): void {
    if (respostaTecnospeed._status == 'sucesso') {
      const respostaTecnospeedSucesso: any = respostaTecnospeed._dados;
      if (respostaTecnospeedSucesso.situacao) {
        financeiroBoleto.protocoloImpressao = respostaTecnospeedSucesso.protocolo;
      } else {
        financeiroBoleto.statusDescricao = respostaTecnospeed._mensagem;
        financeiroBoleto.situacao = respostaTecnospeed._mensagem;
        financeiroBoleto.status = 3;
      }
      super.persistir(new Transporte(financeiroBoleto), this.bibServico.financeiroBoleto, null).subscribe(() => {
        if (!this.utilSessao.falha && financeiroBoleto.protocoloImpressao) {
          window.open(this.bibServicoBoletoTecnoSpeed.getUrlGerarBoletoPdf(this.bibServico.boletoTecnospeed, financeiroBoleto.protocoloImpressao), '_blank');
        }
      });
    }
  }

  criarImpressao(financeiroBoleto: FinanceiroBoleto): Impressao {
    let impressao: Impressao = new Impressao();
    let conta: Conta = this.contas.find((conta) => conta.id == financeiroBoleto.idConta);
    impressao.TipoImpressao = conta.tipoImpressao;
    impressao.Boletos.push(financeiroBoleto.idTecnospeed);
    return impressao;
  }

  atualizarPdf(financeiroBoleto: FinanceiroBoleto): void {
    this.comunicacaoTecnoSpeedBoletoService.listar(this.bibServicoBoletoTecnoSpeed.getUrlBoletoAlterado(this.bibServico.boletoTecnospeed, financeiroBoleto.protocoloAlteracao), this.lojaCnpj).subscribe((res) => {
      if (res._status == 'sucesso') {
        res._dados._sucesso.forEach((sucesso) => {
          financeiroBoleto.statusDescricao = sucesso.situacao;
          financeiroBoleto.situacao = sucesso.mensagem;
          financeiroBoleto.dataRemessa = new Date();
          super.persistir(new Transporte(financeiroBoleto), this.bibServico.financeiroBoleto, null);
          window.open(this.bibServicoBoletoTecnoSpeed.getUrlGerarBoletoPdf(this.bibServico.boletoTecnospeed, financeiroBoleto.protocoloImpressao), '_blank');
        });
      }
    });
  }

  enviarRemessa(financeiroBoleto: FinanceiroBoleto): void {
    this.comunicacaoTecnoSpeedBoletoService.persistir(this.bibServicoBoletoTecnoSpeed.getUrlBoletoRemessa(this.bibServico.remessaTecnospeed), [financeiroBoleto.idTecnospeed], this.lojaCnpj).subscribe((res) => {
      this.atualizarDados(financeiroBoleto, res);
    });
  }

  atualizarDados(financeiroBoleto: FinanceiroBoleto, respostaTecnospeed: any): void {
    if (respostaTecnospeed._status == 'sucesso') {
      respostaTecnospeed._dados._sucesso.forEach((sucesso) => {
        financeiroBoleto.statusDescricao = sucesso.situacao;
        financeiroBoleto.situacao = sucesso.mensagem;
        financeiroBoleto.dataRemessa = new Date();
        super.persistir(new Transporte(financeiroBoleto), this.bibServico.financeiroBoleto, null);
      });
    }
  }

  enviarCancelamento(financeiroBoleto: FinanceiroBoleto): void {
    if (confirm(this.bibDialogo.desejaExcluitBoleto + ' ' + financeiroBoleto.numero)) {
      this.comunicacaoTecnoSpeedBoletoService.persistir(this.bibServicoBoletoTecnoSpeed.getUrlBoletoCancelamento(this.bibServico.boletoTecnospeed), [financeiroBoleto.idTecnospeed], this.lojaCnpj).subscribe((res) => {
        if (res._status == 'sucesso') {
          financeiroBoleto.statusDescricao = res._dados.situacao;
          financeiroBoleto.situacao = res._mensagem;
          financeiroBoleto.protocoloCancelamento = res._dados.protocolo;
          financeiroBoleto.status = -3;
          super.persistir(new Transporte(financeiroBoleto), this.bibServico.financeiroBoleto, null);
          this.comunicacaoTecnoSpeedBoletoService.listar(this.bibServicoBoletoTecnoSpeed.getUrlCancelamento(this.bibServico.boletoTecnospeed, financeiroBoleto.protocoloCancelamento), this.lojaCnpj).subscribe((res) => {
            if (res._status == 'sucesso') {
              res._dados._sucesso.forEach((sucesso) => {
                financeiroBoleto.statusDescricao = sucesso.situacao;
                financeiroBoleto.situacao = sucesso.mensagem;
                financeiroBoleto.status = -3;
                super.persistir(new Transporte(financeiroBoleto), this.bibServico.financeiroBoleto, null);
              });
            }
          });
        }
      });
    }
  }

  alterarBoleto(financeiroBoleto: FinanceiroBoleto): void {
    let alteracaoRemessa: AlteracaoRemessa = this.criarAlteracaoBoleto(financeiroBoleto);
    this.comunicacaoTecnoSpeedBoletoService.persistir(this.bibServicoBoletoTecnoSpeed.getUrlAlterarBoleto(this.bibServico.boletoTecnospeed), alteracaoRemessa, this.lojaCnpj).subscribe((res) => {
      if (res._status == 'sucesso') {
        financeiroBoleto.protocoloAlteracao = res._dados.protocolo;
        financeiroBoleto.situacao = res._dados.situacao;
        financeiroBoleto.statusDescricao = res._mensagem;
        financeiroBoleto.status = 20;
        super.persistir(new Transporte(financeiroBoleto), this.bibServico.financeiroBoleto, null);
      }
    });
  }

  reutilizarBoletoCancelado(financeiro: Financeiro): void {
    if (confirm(this.bibDialogo.reutilizarFinanceiroNovoBoletoMensagem)) {
      financeiro.financeiroBoleto.idFinanceiro = null;
      super.persistir(new Transporte(financeiro.financeiroBoleto), this.bibServico.financeiroBoleto, null);
      financeiro.idFinanceiroBoleto = null;
      super.persistir(new Transporte(financeiro), this.bibServico.financeiro, null);
      let criterios: Criterio[] = [new Criterio('ID', financeiro.id.toString())];
      this.listar(criterios, 0, 0, null);
    }
  }

  criarAlteracaoBoleto(financeiroBoleto: FinanceiroBoleto): AlteracaoRemessa {
    let alteracaoRemessa: AlteracaoRemessa = new AlteracaoRemessa();
    let financeiro: Financeiro = this.financeiros.find((financeiro) => financeiro.id == financeiroBoleto.idFinanceiro);
    if (financeiro.valorRestante != financeiroBoleto.valor) {
      alteracaoRemessa.tipo = '1';
      let boletoAlteracao = new BoletoAlteracao();
      boletoAlteracao.TituloValor = financeiro.valorRestante.toFixed(2).replace('.', ',');
      boletoAlteracao.idintegracao = financeiroBoleto.idTecnospeed;
      alteracaoRemessa.boletos.push(boletoAlteracao);
      financeiroBoleto.valor = financeiro.valorRestante;
    } else {
      alteracaoRemessa.tipo = '0';
      let boletoAlteracao = new BoletoAlteracao();
      boletoAlteracao.TituloDataVencimento = this.util.formatarDataBoleto(financeiro.dataNegociacao);
      boletoAlteracao.idintegracao = financeiroBoleto.idTecnospeed;
      alteracaoRemessa.boletos.push(boletoAlteracao);
      financeiroBoleto.dataVencimento = financeiro.dataVencimento;
    }
    super.persistir(new Transporte(financeiroBoleto), this.bibServico.financeiroBoleto, null);
    return alteracaoRemessa;
  }

  selecionarFinanceiros(marcado: string): void {
    this.valorTotalBoleto = 0;
    this.financeiros.forEach((financeiro) => {
      if (!financeiro.financeiroBoleto || financeiro.financeiroBoleto.status == 0 || financeiro.financeiroBoleto.status == 3) {
        if (financeiro.selecionado != marcado) {
          financeiro.selecionado = marcado == 'S' ? 'N' : 'S';
          this.setFinanceiroSelecionado(financeiro);
        } else {
          if (financeiro.selecionado == 'S') {
            this.valorTotalBoleto += financeiro.valorRestante * (financeiro.tipo == 'D' ? -1 : 1);
          }
        }
      }
    });
    if (marcado == 'N') {
      this.valorTotalBoleto = 0;
    }
  }

  ehEnviarEmail(idParceiro: number, idTecnospeed: string): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', idParceiro)), this.bibServico.parceiro).subscribe((res) => {
      const parceiro: Parceiro = this.plainToClass(Parceiro, res[0]) as any;
      let loja = this.utilSessao.getLojas().find((loja) => loja.id == this.financeiros[0].idLoja);
      if (this.ehValidoLojaEmail(loja) && this.ehValidoParceiroEmail(parceiro)) {
        if (confirm(this.util.substituir(this.bibDialogo.enviarBoletoParaCliente + '?', [parceiro.email]))) {
          this.enviarEmail(idTecnospeed, parceiro.cnpjCpf, loja.nomeFantasia, loja.email, parceiro.email, this.bibDialogo.boleto, this.bibDialogo.boletoMensagem);
        }
      }
    });
  }

  ehValidoLojaEmail(loja: Loja): boolean {
    if (loja && loja.email) {
      return true;
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.lojaNaoPossuiEmailCadastrado));
      return false;
    }
  }

  ehValidoParceiroEmail(parceiro: Parceiro): boolean {
    if (parceiro && parceiro.email) {
      return true;
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.parceiroNaoPossuiEmailCadastrado));
      return false;
    }
  }

  ehValidoParceiroCnpj(parceiro: Parceiro): boolean {
    if (parceiro && parceiro.cnpjCpf) {
      return true;
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.parceiroNaoPossuiCpfCnpjCadastrado));
      return false;
    }
  }

  ehValidoParceiroEndereco(parceiro: Parceiro, parceiroEndereco: any): boolean {
    if (parceiroEndereco && parceiroEndereco.enderecoConcatenado != null && parceiroEndereco.cep != null) {
      return true;
    } else {
      if (parceiroEndereco) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.parceiroDestinoNaoPossuiEnderecoCadastrado));
        return false;
      } else if (parceiro && parceiro.enderecoCompleto != null) {
        return true;
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.parceiroNaoPossuiEnderecoCadastrado));
        return false;
      }
    }
  }

  ehValidoParceiroTelefone(parceiroTelefone: ParceiroTelefone, banco: string): boolean {
    const SICREDI: boolean = banco == '748';
    if (SICREDI) {
      if (parceiroTelefone) {
        return true;
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.parceiroTelefoneObrigatorioParaEsteBanco));
        return false;
      }
    } else {
      return true;
    }
  }

  enviarEmail(idIntegracao: string, sacadoCpfCnpj: string, nomeRementente: string, emailRementente: string, emailDestinatiario: string, emailAssunto: string, emailMensagem: string): void {
    let boletoEmail: BoletoEmail = new BoletoEmail(idIntegracao, sacadoCpfCnpj, nomeRementente, emailRementente, emailDestinatiario, emailAssunto, emailMensagem);
    this.comunicacaoTecnoSpeedBoletoService.persistir(this.bibServicoBoletoTecnoSpeed.getUrlEnviarEmail(this.bibServico.email), boletoEmail, this.lojaCnpj).subscribe(
      (res) => {
        this.utilSessao.setResultado(new Resultado(true, res.mensagem));
      },
      (erro) => {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoFoiPossivelReenviarEmail));
      }
    );
  }

  baixarRemessa(financeiroBoleto: FinanceiroBoleto) {
    this.comunicacaoTecnoSpeedBoletoService.listar(this.bibServicoBoletoTecnoSpeed.getUrlCancelamento(this.bibServico.boletoTecnospeed, financeiroBoleto.protocoloCancelamento), this.lojaCnpj).subscribe((res) => {
      if (res._status == 'sucesso') {
        res._dados._sucesso.forEach((sucesso) => {
          financeiroBoleto.statusDescricao = sucesso.situacao;
          financeiroBoleto.situacao = sucesso.mensagem;
          financeiroBoleto.status = -3;
          this.downloadRemessa(atob(sucesso.remessa));
          super.persistir(new Transporte(financeiroBoleto), this.bibServico.financeiroBoleto, null);
        });
      }
    });
  }

  downloadRemessa(data: any): void {
    const blob = new Blob([data], { type: 'application/rem' });
    const url = window.URL.createObjectURL(blob);
    var link: any = document.createElement('a');
    link.href = url;
    link.download = url.substr(url.lastIndexOf('/') + 1);
    link.click();
  }

  formartarDataPagamento(dateString: string): Date {
    const parts = dateString.split('.');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }

  consultarBoletoEmProssessamento(financeiroBoleto: FinanceiroBoleto): void {
    this.comunicacaoTecnoSpeedBoletoService.listar(this.bibServicoBoletoTecnoSpeed.getUrlConsultaBoleto(this.bibServico.boletoTecnospeed, financeiroBoleto.idTecnospeed), this.lojaCnpj).subscribe((res) => {
      if (res._status == 'sucesso' && res._dados.length > 0) {
        financeiroBoleto.numero = res._dados[0].TituloNossoNumero;
        financeiroBoleto.situacao = res._dados[0].situacao == 'BAIXADO' ? 'CANCELADO' : res._dados[0].situacao;
        if (res._dados[0].PagamentoData != null) {
          financeiroBoleto.dataPagamento = this.formartarDataPagamento(res._dados[0].PagamentoData.split(" ")[0].replace('/', '.').replace('/', '.'));// formattedDate;//res._dados[0].PagamentoData != null ? res._dados[0].PagamentoData.split(" ")[0].replace('/', '.').replace('/', '.') : null;
        }
        financeiroBoleto.status = financeiroBoleto.situacao == 'REGISTRADO' || financeiroBoleto.situacao == 'LIQUIDADO' ? 100 : financeiroBoleto.situacao == 'FALHA' || financeiroBoleto.situacao == 'REJEITADO' ? 3 : -1;
        financeiroBoleto.statusDescricao = res._dados[0].situacao;

        let valorPagoString: string = res._dados[0].TituloValor.replace('.', '');
        valorPagoString = valorPagoString.replace(',', '.');
        financeiroBoleto.valorPago = financeiroBoleto.situacao == 'LIQUIDADO' ? parseFloat(valorPagoString) : financeiroBoleto.valorPago;
        financeiroBoleto.descontoConcedido = financeiroBoleto.valorPago > 0 && financeiroBoleto.valorPago < financeiroBoleto.valor ? financeiroBoleto.valor - financeiroBoleto.valorPago : financeiroBoleto.descontoConcedido;
        financeiroBoleto.acrescimoPago = financeiroBoleto.valorPago > 0 && financeiroBoleto.valorPago > financeiroBoleto.valor ? financeiroBoleto.valorPago - financeiroBoleto.valor : financeiroBoleto.acrescimoPago

        if (res._dados[0].situacao == 'FALHA' || res._dados[0].situacao == 'REJEITADO') {
          financeiroBoleto.status = -1
          financeiroBoleto.statusDescricao = res._dados[0].situacao + ' - ' + res._dados[0].motivo;
        }
        super.persistir(new Transporte(financeiroBoleto), this.bibServico.financeiroBoleto, null).subscribe((res) => {
          if (!this.utilSessao.falha) {
            let criterios: Criterio[] = [new Criterio('ID', financeiroBoleto.idFinanceiro.toString())];
            this.listar(criterios, 0, 0, null);
          }
        });
      } else {
        financeiroBoleto.status = 0;
        financeiroBoleto.statusDescricao = "";
        super.persistir(new Transporte(financeiroBoleto), this.bibServico.financeiroBoleto, null).subscribe((res) => {
          if (!this.utilSessao.falha) {
            let criterios: Criterio[] = [new Criterio('ID', financeiroBoleto.idFinanceiro.toString())];
            this.listar(criterios, 0, 0, null);
          }
        });
      }
    });
  }

  abrirJsonConsulaBoleto(idTecnospeed: string): void {
    this.comunicacaoTecnoSpeedBoletoService.listar(this.bibServicoBoletoTecnoSpeed.getUrlConsultaBoleto(this.bibServico.boletoTecnospeed, idTecnospeed), this.lojaCnpj).subscribe((res) => {
      const blob = new Blob([JSON.stringify(res)], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    })
  }

  ngOnDestroy() {
    clearInterval(this.intervaloAtualizarBoletos);
  }
}
