import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.aguardando, id: 'A' },
  { nome: bibDialogo.cancelada, id: 'C' },
  { nome: bibDialogo.sucesso, id: 'S' },
  { nome: bibDialogo.todos, id: '-' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.numero, nome: 'NUMERO', coluna: 6, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.receita, nome: 'RECEITA', coluna: 6, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date(), obrigatorio: 'S' },
  { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date(), obrigatorio: 'S' },
  { rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.agronomo, nome: 'ID_AGRONOMO', coluna: 12, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca, obrigatorio: 'S', criterios: [new Criterio('AGRONOMO', 'S')] },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.situacao, nome: 'SITUACAO_RECEITUARIO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'A', lista: filtroSituacao },
]);
export default filtros;
