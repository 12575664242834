<layout [titulo]="conta.nome" [id]="conta.id" (voltarAvancarEmt)="ehAlteracao()">
  <div class="row">
    <texto class="col-sm" [id]="'nome'" [rotulo]="bibDialogo.nome" [campo]="conta.nome" (alteracao)="conta.nome = $event" [obrigatorio]="true" [foco]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="conta.ativo" (alteracao)="conta.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row">
    <lista class="col-sm-4" [id]="'tipoConta'" [rotulo]="bibDialogo.tipoConta" [campo]="conta.tipo" [lista]="tipoConta" (alteracao)="setTipoConta($event)" [obrigatorio]="true"></lista>
    <lista *ngIf="conta.tipo == 'C'" class="col-sm-4" [id]="'exigeAberturaCaixa'" [rotulo]="bibDialogo.exigeAberturaCaixa" [campo]="conta.exigeAberturaCaixa" [lista]="opcoes" (alteracao)="conta.exigeAberturaCaixa = $event.id" [obrigatorio]="conta.tipo == 'C'"></lista>
  </div>
  <div *ngIf="conta.tipo == 'B'" class="row align-items-center">
    <texto class="col-sm-4" [id]="'numero'" [rotulo]="bibDialogo.numero" [campo]="conta.numero" (alteracao)="conta.numero = $event" [obrigatorio]="true"></texto>
    <texto class="col-sm-3" [id]="'digito'" [rotulo]="bibDialogo.digito" [campo]="conta.digito" (alteracao)="conta.digito = $event" [maximoCaracteres]="1" [ajuda]="bibDialogo.maximoUmDigito" [obrigatorio]="conta.numeroBanco"></texto>
  </div>
  <div class="row">
    <decimal class="col-sm-4" [id]="'limite'" [rotulo]="bibDialogo.limite" [campo]="conta.limite" (alteracao)="conta.limite = $event" [obrigatorio]="true"></decimal>
    <lista *ngIf="conta.tipo == 'B'" class="col-sm-4" [id]="'conciliacaoAutomatica'" [rotulo]="bibDialogo.conciliacaoAutomaticaAbreviada" [campo]="conta.conciliacaoAutomatica" [lista]="opcoes" (alteracao)="conta.conciliacaoAutomatica = $event.id" [obrigatorio]="true"></lista>
  </div>
  <div class="row">
    <lista *ngIf="ehSpedRegimeCaixa" class="col-sm-4" [id]="'enviarSped'" [rotulo]="bibDialogo.enviarSped" [campo]="conta.enviarSped" [lista]="opcoes" (alteracao)="conta.enviarSped = $event.id" [ajuda]="bibDialogo.ajudaEnviarSped" [obrigatorio]="true" [ajudaDireita]="true"></lista>
  </div> 
  <div class="row">
    <lista *ngIf="lojas.length > 1 && conta.tipo == 'B'" class="col-sm" [id]="'lojaVinculada'" [rotulo]="bibDialogo.lojaVinculada" [campo]="conta.idLojaVinculada" (alteracao)="conta.idLojaVinculada = $event.id" [tipo]="bibPropriedade.lista.lojaUsuario" [icone]="bibIcone.atualizar" [ajuda]="bibDialogo.lojaVinculadaMensagem" [ajudaDireita]="true"></lista>
  </div>   
  <div *ngIf="conta.tipo == 'B' && utilSessao.sistema == 'aGrow-w'" class="mt-2 section-deck">
    <section class="card card-fluid">
      <header class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item">
            <a class="nav-link active show" data-toggle="tab" id="AbaBoleta" href="#boleto">{{ bibDialogo.boleto }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" id="AbaRemessa" href="#remessa">{{ bibDialogo.remessa }}</a>
          </li>
        </ul>
      </header>
      <div class="card-body">
        <div id="myTabContent" class="tab-content">
          <div class="tab-pane active show" id="boleto">
            <div class="justify-content-end" >
              <p class="mr-4">{{ bibDialogo.boletoTutorial }}<a href="https://bucket-agrow-producao.s3.us-east-2.amazonaws.com/arquivos/conta/configurar_boleto.pdf" target="_blank">{{ bibDialogo.cliqueAqui }}</a></p>
              <p>{{ bibDialogo.bancosHomologados }}<a href="https://bucket-agrow-producao.s3.us-east-2.amazonaws.com/arquivos/conta/bancos_homologados.pdf" target="_blank">{{ bibDialogo.cliqueAqui }}</a></p>
            </div>
            <div class="row">
              <lista class="col-sm" [id]="'loja'" [rotulo]="bibDialogo.lojaBoleto" [campo]="conta.idLojaBoleto" (alteracao)="conta.idLojaBoleto = $event.id" [tipo]="bibPropriedade.lista.lojaUsuario" [icone]="bibIcone.atualizar" [ajuda]="bibDialogo.lojaBoletoMensagem" [ajudaDireita]="true"></lista>
            </div>
            <div class="row">
              <lista class="col-sm-4" [id]="'numeroBanco'" [rotulo]="bibDialogo.numeroBanco" [campo]="conta.numeroBanco" [lista]="numeroBancos" (alteracao)="conta.numeroBanco = $event.id" (click)="mudarBanco(conta.numeroBanco)"></lista>
              <lista class="col-sm-4" [id]="'ambiente'" [rotulo]="bibDialogo.ambiente" [campo]="conta.homologacao" (alteracao)="conta.homologacao = $event.id" [lista]="homologacaoProducao" [obrigatorio]="conta.numeroBanco"></lista>
              <texto class="col-sm-4" [id]="'especie'" [rotulo]="bibDialogo.especie" [campo]="conta.especie" (alteracao)="conta.especie = $event" [obrigatorio]="conta.numeroBanco"></texto>
            </div>
            <div class="row">
              <texto class="col-sm-3" [id]="'numeroBoleto'" [rotulo]="bibDialogo.numeracaoBoleto" [campo]="conta.numeroBoleto" [tipo]="this.bibPropriedade.texto.inteiro" (alteracao)="conta.numeroBoleto = $event" [obrigatorio]="conta.numeroBanco"></texto>
              <lista class="col-sm-3" [id]="'tipoContaBancaria'" [rotulo]="bibDialogo.tipo" [campo]="conta.tipoContaBancaria" [lista]="tipoContaBancaria" (alteracao)="conta.tipoContaBancaria = $event.id" [obrigatorio]="conta.numeroBanco"></lista>
              <texto class="col-sm-3" [id]="'carteira'" [rotulo]="conta?.numeroBanco == '001' ? bibDialogo.variacaoCarteira : bibDialogo.carteira" [campo]="conta.carteira" (alteracao)="conta.carteira = $event" [obrigatorio]="conta.numeroBanco" [ajuda]="conta?.numeroBanco == '001' ? bibDialogo.ajudaCarteira : null"> </texto>
              <texto class="col-sm-3" [id]="'carteira'" [rotulo]="bibDialogo.diasCompensacao" [campo]="conta.diasCompensacao" (alteracao)="conta.diasCompensacao = $event" [ajuda]=""> </texto>
            </div>
            <div class="row">
              <texto class="col-sm-4" [id]="'codigoAgencia'" [rotulo]="bibDialogo.codigoAgencia" [campo]="conta.agenciaBanco" (alteracao)="conta.agenciaBanco = $event" [obrigatorio]="conta.numeroBanco"></texto>
              <texto class="col-sm-4" [id]="'digitoAgencia'" [rotulo]="bibDialogo.digitoAgencia" [campo]="conta.digitoBanco" (alteracao)="conta.digitoBanco = $event" [maximoCaracteres]="1" [ajuda]="bibDialogo.maximoUmDigito" [obrigatorio]="conta.numeroBanco"></texto>
              <texto class="col-sm-4" [id]="'codigoCedente'" [rotulo]="bibDialogo.codigoCedente" [campo]="conta.cedente" (alteracao)="conta.cedente = $event" [obrigatorio]="conta.numeroBanco"></texto>
            </div>
            <div class="row">
              <lista class="col-sm-4" [id]="'registroInstantaneo'" [rotulo]="bibDialogo.envioInstantaneo" [campo]="conta.registroInstantaneo" [lista]="opcoes" (alteracao)="conta.registroInstantaneo = $event.id" [ajuda]="bibDialogo.ajudaEnvioInstantaneo" [obrigatorio]="true"></lista>
              <lista class="col-sm-4" [id]="'tipoImpressao'" [rotulo]="bibDialogo.tipoImpressao" [campo]="conta.tipoImpressao" [lista]="tipoImpressao" (alteracao)="conta.tipoImpressao = $event.id" [obrigatorio]="conta.numeroBanco"></lista>
              <texto class="col-sm-4" [id]="'convenio'" [rotulo]="bibDialogo.convenio" [campo]="conta.convenio" (alteracao)="conta.convenio = $event" [obrigatorio]="conta.numeroBanco"></texto>
            </div>
            <div class="row">
              <texto class="col-sm-4" [id]="'idBanco'" [rotulo]="bibDialogo.apiId" [campo]="conta.idBanco" (alteracao)="conta.idBanco = $event"></texto>
              <texto class="col-sm-4" [id]="'senhaBanco'" [rotulo]="bibDialogo.apiKey" [campo]="conta.senhaBanco" (alteracao)="conta.senhaBanco = $event"></texto>
              <texto class="col-sm-4" [id]="'segredoBanco'" [rotulo]="bibDialogo.apiSecret" [campo]="conta.segredoBanco" (alteracao)="conta.segredoBanco = $event"></texto>
            </div>
            <div class="row">
              <texto class="col-sm-12" [id]="'localPagamento'" [rotulo]="bibDialogo.localPagamento" [campo]="conta.localPagamento" (alteracao)="conta.localPagamento = $event"></texto>
              <texto class="col-sm-12" [id]="'instrucoesBanco'" [rotulo]="bibDialogo.instrucoesBanco" [campo]="conta.instrucoes" (alteracao)="conta.instrucoes = $event"></texto>
            </div>
          </div>
          <div class="tab-pane" id="remessa">
            <div class="row">
              <lista class="col-sm-4" [id]="'layout'" [rotulo]="bibDialogo.layout" [campo]="conta.layout" [lista]="opcoesRemessaLayout" (alteracao)="conta.layout = $event.id" [obrigatorio]="conta.numeroBanco"></lista>
              <texto class="col-sm-4" [id]="'sequenciaRemessa'" [rotulo]="bibDialogo.sequenciaRemessa" [campo]="conta.sequenciaRemessa" (alteracao)="conta.sequenciaRemessa = $event"></texto>
              <!-- <texto class="col-sm-4" [id]="'codigoTransmissao'" [rotulo]="bibDialogo.codigoTransmissao" [campo]="conta.codigoTransmissao" (alteracao)="conta.codigoTransmissao = $event"></texto> -->
            </div>
            <div class="row">
              <lista class="col-sm-4" [id]="'tipoDesconto'" [rotulo]="bibDialogo.tipoDesconto" [campo]="conta.tipoDesconto" [lista]="tipoDescontos" (alteracao)="conta.tipoDesconto = $event.id" [obrigatorio]="conta.numeroBanco && conta.tipoDesconto != 0"></lista>
              <decimal class="col-sm-4" [id]="'valorDesconto'" [rotulo]="bibDialogo.valorDesconto" [campo]="conta.valorDesconto" (alteracao)="conta.valorDesconto = $event ? $event : 0" [obrigatorio]="conta.numeroBanco && conta.tipoDesconto != 0"></decimal>
              <texto class="col-sm-4" [id]="'diasDesconto'" [rotulo]="bibDialogo.diasDesconto" [campo]="conta.diasDesconto" (alteracao)="conta.diasDesconto = $event" [obrigatorio]="conta.numeroBanco && conta.tipoDesconto != 0"></texto>
            </div>
            <div class="row">
              <lista class="col-sm-4" [id]="'tipoMulta'" [rotulo]="bibDialogo.tipoMulta" [campo]="conta.tipoMulta" [lista]="tipoMultas" (alteracao)="conta.tipoMulta = $event.id" [obrigatorio]="conta.numeroBanco && conta.tipoMulta != 0"></lista>
              <decimal class="col-sm-4" [id]="'percentualMulta'" [rotulo]="bibDialogo.percentualMulta" [campo]="conta.percentualMulta" (alteracao)="conta.percentualMulta = $event ? $event : 0" [valorMaximo]="100" [obrigatorio]="conta.numeroBanco && conta.tipoMulta != 0"></decimal>
              <texto class="col-sm-4" [id]="'diasMulta'" [rotulo]="bibDialogo.diasMulta" [campo]="conta.diasMulta" (alteracao)="conta.diasMulta = $event" [obrigatorio]="conta.numeroBanco && conta.tipoMulta != 0"> </texto>
            </div>
            <div class="row">
              <lista class="col-sm-4" [id]="'tipoJurosMora'" [rotulo]="bibDialogo.tipoJurosMora" [campo]="conta.tipoJuros" [lista]="opcoesTipoJuros" (alteracao)="conta.tipoJuros = $event.id" [obrigatorio]="conta.numeroBanco"></lista>
              <decimal class="col-sm-4" [id]="'taxaJurosMora'" [rotulo]="bibDialogo.taxaJurosMora" [campo]="conta.taxaJuros" (alteracao)="conta.taxaJuros = $event ? $event : 0" [valorMaximo]="100" [obrigatorio]="conta.numeroBanco && conta.tipoJuros != '1'"></decimal>
              <texto class="col-sm-4" [id]="'diasJurosMora'" [rotulo]="bibDialogo.diasJurosMora" [campo]="conta.diasJuros" (alteracao)="conta.diasJuros = $event" [obrigatorio]="conta.numeroBanco && conta.tipoJuros != '1'"></texto>
            </div>
            <div class="row">
              <lista class="col-sm-4" [id]="'tipoProtesto'" [rotulo]="bibDialogo.tipoProtesto" [campo]="conta.tipoProtesto" [lista]="tipoProtestos" (alteracao)="conta.tipoProtesto = $event.id" [obrigatorio]="conta.numeroBanco"></lista>
              <div class="col-sm-4"></div>
              <texto class="col-sm-4" [id]="'diasProtesto'" [rotulo]="bibDialogo.diasProtesto" [campo]="conta.diasProtesto" (alteracao)="conta.diasProtesto = $event" [obrigatorio]="conta.numeroBanco && this.conta.tipoProtesto != 3"></texto>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div *ngIf="empresaUsaContabilidade" class="row">
    <titulo *ngIf="!compacto" class="col-sm-12" [titulo]="bibDialogo.contaContabil" [imagem]="bibImagem.contabilidade"></titulo>
    <busca class="col-sm-12" [rotulo]="bibDialogo.contaContabil" [campo]="conta.contaContabil" (alteracao)="conta.idContaContabil = $event.id" [servico]="bibServico.contaContabil" [buscarPorNumero]="true"></busca>
  </div>
  <lista [id]="'lojaOrigem'" [rotulo]="bibDialogo.lojaOrigem" [tipo]="bibPropriedade.lista.lojaUsuario" [campo]="conta.idLoja" [obrigatorioLoja]="true" (alteracao)="conta.idLoja = $event.id" [desabilitado]="conta.id"></lista>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirConta()" [desabilitado]="bloquearPermissao(conta.id)"></botao>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="persistirConta(true)" [desabilitado]="bloquearPermissao(conta.id)"></botao>
<auditoria *ngIf="conta.id" [idUsuarioInclusao]="conta.idUsuarioInclusao" [dataHoraInclusao]="conta.dataHoraInclusao" [idUsuarioAlteracao]="conta.idUsuarioAlteracao" [dataHoraAlteracao]="conta.dataHoraAlteracao"></auditoria>
