import { Filtro } from 'src/app/modelo/filtro';
import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';
import { Criterio } from 'src/app/modelo/criterio';

const filtroComboQuantidade: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.todos, id: '-' },
  { nome: bibDialogo.disponivel, id: 'EST.QUANTIDADE_DISPONIVEL' },
  { nome: bibDialogo.nfe, id: 'EST.QUANTIDADE_NFE' },
  { nome: bibDialogo.pendente, id: 'EST.QUANTIDADE_RESERVADA' },
]);

const valores: string[] = ['EST.QUANTIDADE_DISPONIVEL', , 'EST.QUANTIDADE_NFE', 'EST.QUANTIDADE_RESERVADA'];

const filtros: Filtro[] = plainToClass(Filtro, [
  { id: 'numero', rotulo: bibDialogo.numeroProdutoAbreviacao, nome: 'NUMERO', coluna: 6, tipo: bibPropriedade.filtro.numero },
  { id: 'referencia', rotulo: bibDialogo.referencia, nome: 'REFERENCIA', coluna: 6, tipo: bibPropriedade.filtro.texto },
  { id: 'nome', rotulo: bibDialogo.nome, nome: 'NOME', coluna: 6, tipo: bibPropriedade.filtro.texto },
  { id: 'exceto', rotulo: bibDialogo.exceto, nome: 'EXCETO', coluna: 6, tipo: bibPropriedade.filtro.texto, ajuda: bibDialogo.ajudaExceto },
  { rotulo: bibDialogo.grupo, nome: 'ID_GRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('GRUPO_PAI', '')], vincularValor: 'S' },
  { rotulo: bibDialogo.subGrupo, nome: 'ID_SUBGRUPO', coluna: 6, servicoWeb: bibServico.grupo, tipo: bibPropriedade.filtro.busca, valorVinculado: 'SUBGRUPO', criterios: [new Criterio('SUBGRUPO', '')], vincularValor: 'N' },
  { id: 'fabricante', rotulo: bibDialogo.fabricante, nome: 'ID_FABRICANTE', coluna: 6, servicoWeb: bibServico.fabricante, tipo: bibPropriedade.filtro.busca },
  { id: 'categoria', rotulo: bibDialogo.deposito, nome: 'ID_CATEGORIA', coluna: 6, servicoWeb: bibServico.categoria, tipo: bibPropriedade.filtro.busca },
  { id: 'loja', rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 12, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista, obrigatorio: 'S' },
  { id: 'local', rotulo: bibDialogo.local, nome: 'ID_LOCAL', coluna: 6, servicoWeb: bibServico.local, tipo: bibPropriedade.filtro.busca },
  { id: 'lote', rotulo: bibDialogo.lote, nome: 'ID_LOTE', coluna: 6, servicoWeb: bibServico.lote, tipo: bibPropriedade.filtro.busca },
  { id: 'operador', rotulo: bibDialogo.quantidade, nome: 'OPERADOR', obrigatorio: 'S', coluna: 12, tipo: bibPropriedade.filtro.combo, valor: '-', lista: filtroComboQuantidade },
  { id: 'quantidadeMaior', rotulo: bibDialogo.maiorIgual, nome: 'QUANTIDADE_MAIOR', coluna: 6, tipo: bibPropriedade.filtro.numero, vinculaBloqueado: 'OPERADOR', bloqueioInicial: true, bloqueados: valores },
  { id: 'quantidadeMenor', rotulo: bibDialogo.menorIgual, nome: 'QUANTIDADE_MENOR', coluna: 6, tipo: bibPropriedade.filtro.numero, vinculaBloqueado: 'OPERADOR', bloqueioInicial: true, bloqueados: valores },
  { id: 'VencimentodataDe', rotulo: bibDialogo.dataVencimentoDe, nome: 'VENCIMENTO_DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { id: 'VencimentoAte', rotulo: bibDialogo.dataVencimentoAte, nome: 'VENCIMENTO_DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
]);
export default filtros;
