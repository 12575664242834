import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboStatus: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: '01 - ' + bibDialogo.aberto, id: 'N' },
  { nome: '02 - ' + bibDialogo.concluido, id: 'S' },
  { nome: '03 - ' + bibDialogo.todos, id: '-' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.nMovimentacao, nome: 'NUMERO', coluna: 6, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.nReceituario, nome: 'NUMERO_RECEITUARIO', coluna: 6, tipo: bibPropriedade.filtro.numero },
  { rotulo: bibDialogo.dataDe, nome: 'DATA_DE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date(), obrigatorio: 'S' },
  { rotulo: bibDialogo.dataAte, nome: 'DATA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date(), obrigatorio: 'S' },
  { rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.parceiro, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 12, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.produto, nome: 'ID_PRODUTO', coluna: 12, servicoWeb: bibServico.produto, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { id: 'situacao', rotulo: bibDialogo.situacao, nome: 'RECEITUARIO_STATUS', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'N', lista: filtroComboStatus },
]);
export default filtros;
